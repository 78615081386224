import { Component } from '@angular/core';
import { ReportingAuthService } from '@pw/reporting/auth';

@Component({
  selector: 'pw-logout',
  standalone: false,
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent {
  public readonly pageTitle = `Sign out successful`;
  public readonly descriptions = ['You are now signed out'];

  constructor(private readonly authService: ReportingAuthService) {
    // This ensures we bypass the logout page (Directly takes the user to Azure login page)
    this.login();
  }

  login() {
    this.authService.azureLogin();
  }
}
