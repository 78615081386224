import { APP_BASE_HREF, CommonModule, PlatformLocation } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MsalBroadcastService, MsalGuard, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { getBaseHref } from '@klg/shared/utils';
import { ButtonComponent } from '@klg/shared/ui/button';
import { PATHWAYS_INCIDENT_BASE_PATH } from '@pw/api/incident';
import { PATHWAYS_INSTITUTION_BASE_PATH } from '@pw/api/institution';
import { HttpErrorInterceptor, msalGuardConfig, MSALInstanceFactory } from '@pw/reporting/auth';
import { PATHWAYS_ENVIRONMENT } from '@pw/shared/environment';
import { PathwaysUiModule, pwPreset } from '@pw/shared/ui';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { reportingRoutes } from './app.routes';
import { LogoutComponent } from './auth/logout/logout.component';
import { BasePageComponent } from './components/base-page/base-page.component';
import { ReportingForbiddenPageComponent } from './components/forbidden-page/forbidden-page.component';
import { ReportingNotFoundPageComponent } from './components/not-found-page/not-found-page.component';
import { ToastModule as PrimeNgToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { APPLICATION_IMPORTS } from '@klg/shared/app-init';
import { pwHttpInterceptors } from '@pw/shared/http-utils';
import { provideNotificationService } from '@klg/shared/logger';
import { PwToastNotificationService } from '@pw/shared/logger';
import { APP_NAME, COMPANY } from '@klg/shared/tokens';
import { COMPANIES } from '@klg/shared/types';
import { provideAnimations } from '@angular/platform-browser/animations';
import { providePrimeNG } from 'primeng/config';

@NgModule({
  declarations: [AppComponent, BasePageComponent, LogoutComponent, ReportingForbiddenPageComponent, ReportingNotFoundPageComponent],
  imports: [...APPLICATION_IMPORTS, PrimeNgToastModule, CommonModule, FormsModule, PathwaysUiModule, RouterModule.forRoot(reportingRoutes), ButtonComponent],
  providers: [
    { provide: APP_BASE_HREF, useFactory: getBaseHref, deps: [PlatformLocation] },
    { provide: COMPANY, useValue: COMPANIES.PATHWAYS },
    { provide: APP_NAME, useValue: 'student-incident-reporting-tool' },
    { provide: PATHWAYS_ENVIRONMENT, useValue: environment },
    { provide: PATHWAYS_INCIDENT_BASE_PATH, useValue: environment.API_BASE_PATH },
    { provide: PATHWAYS_INSTITUTION_BASE_PATH, useValue: environment.API_BASE_PATH },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory, deps: [APP_BASE_HREF] },
    { provide: MSAL_GUARD_CONFIG, useValue: msalGuardConfig },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    MessageService,
    provideNotificationService(PwToastNotificationService),
    ...pwHttpInterceptors,
    provideAnimations(),
    providePrimeNG({
      theme: {
        preset: pwPreset,
        options: {
          darkModeSelector: false,
        },
      },
    }),
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
