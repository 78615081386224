import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportingAuthService, User } from '@pw/reporting/auth';
import { ReportingRoutes } from '@pw/shared/environment';
import { Subscription } from 'rxjs';

@Component({
  templateUrl: './base-page.component.html',
  standalone: false,
  styleUrls: ['./base-page.component.scss'],
})
export class BasePageComponent implements OnInit, OnDestroy {
  public readonly mainPage = ReportingRoutes.INCIDENTS;
  public userName = '';
  public isLogoutInProgress = false;

  private subscriptions = new Subscription();

  constructor(
    private readonly authService: ReportingAuthService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    const currentUser: User = this.activatedRoute.snapshot?.data?.currentUser;
    this.userName = currentUser.email;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  logout() {
    this.isLogoutInProgress = true;
    this.authService.azureLogout();
  }
}
