import { Component, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { ReportingAuthService, AutoLogoutService } from '@pw/reporting/auth';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'pw-root',
  standalone: false,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();

  constructor(
    private authService: ReportingAuthService,
    private readonly msalBroadcastService: MsalBroadcastService,
    private autoLogoutService: AutoLogoutService,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.msalBroadcastService.msalSubject$.subscribe(({ eventType, payload }: EventMessage) => {
        switch (eventType) {
          case EventType.LOGIN_SUCCESS:
            this.authService.setAuthenticationResult(payload as AuthenticationResult);
            this.authService.setActiveAccount(payload);
            this.autoLogoutService.addListener();
            break;
          case EventType.SSO_SILENT_SUCCESS:
          case EventType.ACQUIRE_TOKEN_SUCCESS:
            this.autoLogoutService.addListener();
            break;
          case EventType.ACQUIRE_TOKEN_FAILURE:
            this.authService.azureLogout();
            break;

          default:
            break;
        }
      }),
    );

    this.subscriptions.add(
      this.msalBroadcastService.inProgress$.pipe(filter((status: InteractionStatus) => status === InteractionStatus.None)).subscribe(() => {
        this.authService.checkAndSetActiveAccount();
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
